import { FacetFilterValue, FacetOptionsValue } from '../..';
import {
  CommonForumFacetOptions,
  ContentTypeOptions,
  ContentTypeOptionsType,
} from './ForumFacets.types';

export const getListTotals = (list: FacetOptionsValue['contentType'] = []) => {
  return list.reduce((a, b) => a + b.count, 0);
};

export const getActiveType = (
  filter?: FacetFilterValue,
): ContentTypeOptionsType => {
  return (
    (filter?.contentType as ContentTypeOptionsType) ?? ContentTypeOptions.All
  );
};

export const getActiveCategory = (
  filter?: FacetFilterValue,
): CommonForumFacetOptions => {
  return (
    (filter?.categoryTitle as CommonForumFacetOptions) ?? ContentTypeOptions.All
  );
};

const DEFAULT_CONTENT_TYPE_COUNT: Record<ContentTypeOptionsType, number> = {
  [ContentTypeOptions.All]: 0,
  [ContentTypeOptions.Post]: 0,
  [ContentTypeOptions.Comment]: 0,
};

export const getTypeCounts = (
  contentTypes: FacetOptionsValue['contentType'],
): Record<ContentTypeOptionsType, number> => {
  const typeCounts = Object.values(ContentTypeOptions).reduce((acc, key) => {
    if (key === ContentTypeOptions.All) {
      return { ...acc, [key]: getListTotals(contentTypes) };
    }

    return {
      ...acc,
      [key]:
        (contentTypes || []).find(({ label }) => label === key)?.count || 0,
    };
  }, DEFAULT_CONTENT_TYPE_COUNT);

  return typeCounts;
};
