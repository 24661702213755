import React from 'react';
import { useIsomorphicLayoutEffect } from './useIsomorphicLayoutEffect';

// Used as a workaround to trigger full remount of DOM to solve hydration issues
// between SSR and CSR content.
export const useRemount = () => {
  const [key, setKey] = React.useState(0);

  useIsomorphicLayoutEffect(() => {
    setKey(1);
  }, []);

  return key;
};
