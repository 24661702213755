import React, { FC } from 'react';
import { useEnvironment } from '@wix/yoshi-flow-editor';

import { st, classes } from './ProductFacet.st.css';

interface ProductFacetProps {
  title: string;
}

export const ProductFacet: FC<ProductFacetProps> = ({ title, children }) => {
  const { isMobile } = useEnvironment();

  return (
    <div className={st(classes.root, { mobileView: isMobile })}>
      <div className={classes.subtitle}>{title}</div>
      {children}
    </div>
  );
};
