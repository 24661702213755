import { useEnvironment } from '@wix/yoshi-flow-editor';
import React from 'react';
import { Description } from '../../../components/SearchResults/Widget/components/Description';
import { st, classes } from './ProGalleryItemDecorator.st.css';

type ProGalleryItemDecorationProps = {
  item: { description?: string };
};

export function ProGalleryItemDecorator({
  item,
}: ProGalleryItemDecorationProps) {
  const { isMobile } = useEnvironment();

  const { description } = item;
  const showDescription = !!description;
  return showDescription ? (
    <div className={st(classes.root, { mobileView: isMobile })} dir="auto">
      <Description description={description} maxLineCount={2} />
    </div>
  ) : null;
}
