import { IApiError } from '@wix/client-search-sdk';

type Predicate = (a: IApiError) => boolean;

const fallbackErrorMessageKey = 'searchResults.errorMessage.noErrorCode';

const isTemporaryError = buildPredicateFromErrorCodes([
  'SE-0001',
  'SE-0002',
  'SE-0003',
  'SE-0004',
  'SE-0005',
  'SE-0006',
  'SE-0007',
]);

const isInvalidQueryError = buildPredicateFromErrorCodes(['SE-1108']);

const isRateExceededError = buildPredicateFromErrorCodes(['SE-1201']);

const isLanguageNotSupportedError = buildPredicateFromErrorCodes(['SE-1107']);

const isAnotherKnownError: Predicate = ({ code }) => {
  const numericCode = parseInt(`${code}`.replace('SE-', ''), 10);
  return numericCode >= 1001 && numericCode <= 1124;
};

const mappers: { p: Predicate; k: string }[] = [
  { p: isTemporaryError, k: 'searchResults.errorMessage.temporaryError' },
  { p: isInvalidQueryError, k: 'searchResults.errorMessage.invalidQueryError' },
  { p: isRateExceededError, k: 'searchResults.errorMessage.rateExceededError' },
  {
    p: isLanguageNotSupportedError,
    k: 'searchResults.errorMessage.languageNotSupportedError',
  },
  { p: isAnotherKnownError, k: 'searchResults.errorMessage.anotherKnownError' },
];

export function getErrorMessageKey(apiErrorDetails?: IApiError) {
  if (!apiErrorDetails) {
    return fallbackErrorMessageKey;
  }
  const v = mappers.find(({ p }) => p(apiErrorDetails));
  return v ? v.k : fallbackErrorMessageKey;
}

function buildPredicateFromErrorCodes(errorCodes: string[]): Predicate {
  return ({ code }) => code !== undefined && errorCodes.includes(code);
}
