import React from 'react';

const UpvoteIcon = () => {
  // @TODO temporary inline svg, while icon is not available in wix-ui-tp
  return (
    <svg
      width="16"
      height="12"
      viewBox="0 0 16 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.4058 10.6244L15.4059 10.6245C15.5342 10.8105 15.5244 11.0078 15.4183 11.1755C15.3081 11.3498 15.0826 11.5 14.7676 11.5H1.23236C0.917412 11.5 0.691912 11.3498 0.581657 11.1755C0.475563 11.0078 0.465847 10.8105 0.594114 10.6245L0.594138 10.6244L7.3624 0.8058L7.36268 0.805396C7.49325 0.615582 7.73021 0.500002 8.00015 0.500002C8.27009 0.500002 8.50767 0.615606 8.6389 0.80589L15.4058 10.6244Z"
        stroke="currentColor"
      />
    </svg>
  );
};

export default UpvoteIcon;
