import React from 'react';
import { AppSettings } from '@wix/search-settings-client';

import { useAppSettings } from '../hooks';

export interface InjectedAppSettingsProps {
  appSettings: AppSettings;
}

export const withAppSettings = <P extends InjectedAppSettingsProps>(
  WrappedComponent: React.ComponentType<P>,
) => {
  const Wrapped: React.FC<Omit<P, keyof InjectedAppSettingsProps>> = (
    props,
  ) => {
    const appSettings = useAppSettings();
    return <WrappedComponent {...({ ...props, appSettings } as P)} />;
  };

  return Wrapped;
};
