import * as React from 'react';

import { useEnvironment } from '@wix/yoshi-flow-editor';
import { st, classes } from './Title.st.css';
import { stripMarkTags } from '../stripMarkTags';
import { xss } from '../../lib/xss';

interface ITitleProps {
  onClick?(e: React.MouseEvent<HTMLElement>): void;
  title: string;
  url?: string;
}

interface IDataHookAttribute {
  'data-hook'?: string;
}

export const Title: React.FC<ITitleProps> = ({ url, onClick, title }) => {
  const { isMobile, isEditorX, isCssPerBreakpoint } = useEnvironment();

  const commonProps: React.HTMLAttributes<HTMLAnchorElement | HTMLSpanElement> &
    IDataHookAttribute & { className: any } = {
    title: stripMarkTags(title),
    'data-hook': 'item-title',
    onClick,
    dangerouslySetInnerHTML: { __html: xss(title) },
    className: st(classes.root, {
      mobileView: isMobile,
      fluid: isEditorX || isCssPerBreakpoint,
    }),
  };

  if (url) {
    // eslint-disable-next-line jsx-a11y/anchor-has-content
    return <a {...commonProps} href={url} />;
  }
  return <span {...commonProps} />;
};
