import React, { FC, useMemo } from 'react';
import { useEnvironment, useTranslation } from '@wix/yoshi-flow-editor';
import { Checkbox, CheckboxGroup } from 'wix-ui-tpa/cssVars';

import { IWidgetProps } from '../../../../components/SearchResults/Widget/components/Widget';
import { ProductFacet } from '../ProductFacet';
import { st, classes } from './ProductFacetsCollections.st.css';
import { getVisibleCollections } from '../../../../lib/productFacets';
import { AvailableFacets, FacetFilterValue } from '../../..';
import { FacetOptionsMap } from '../../../../types/core/facets';
import { useSearchEnvironment } from '../../../../components/SearchResults/Widget/hooks';

interface ProductFacetsCollectionsProps {
  collections: FacetOptionsMap[AvailableFacets['collections']['type']];
  selectedCollections?: FacetFilterValue['collections'];
  onFacetsFilterChange: IWidgetProps['onFacetsFilterChange'];
}

export const ProductFacetsCollections: FC<ProductFacetsCollectionsProps> = ({
  collections,
  selectedCollections = [],
  onFacetsFilterChange,
}) => {
  const { isMobile } = useEnvironment();
  const { t } = useTranslation();
  const { isDemoContent } = useSearchEnvironment();

  const visibleCollections = useMemo(() => {
    return getVisibleCollections(collections, selectedCollections);
  }, [collections, selectedCollections]);

  const renderLabel = (title: string, count: number) => {
    return (
      <>
        {title} <span className={classes.count}>({count})</span>
      </>
    );
  };

  return (
    <ProductFacet title={t('searchResults.facets.collectionTitle')}>
      <CheckboxGroup className={st(classes.root, { mobileView: isMobile })}>
        {visibleCollections.map(({ label, count }) => (
          <Checkbox
            data-hook={`facet-collection-item-${label}`}
            className={classes.checkbox}
            label={renderLabel(isDemoContent ? t(label) : label, count)}
            checked={selectedCollections.includes(label)}
            key={label}
            onChange={({ checked }) => {
              onFacetsFilterChange({
                collections: checked
                  ? [...selectedCollections, label]
                  : [...selectedCollections.filter((v) => v !== label)],
              });
            }}
          />
        ))}
      </CheckboxGroup>
    </ProductFacet>
  );
};
