import * as React from 'react';

import { IApiError } from '@wix/client-search-sdk';

import { getErrorMessageKey } from './getErrorMessageKey';

import { st, classes } from './ResultsStatus.st.css';
import { st as sls, classes as stc } from '../StatusLine/StatusLine.st.css';
import { useTranslation } from '@wix/yoshi-flow-editor';

type FailedMessageProps = {
  isMobile: boolean;
  apiErrorDetails?: IApiError;
};

export const FailedMessage: React.FC<FailedMessageProps> = ({
  isMobile,
  apiErrorDetails,
}) => {
  const { t } = useTranslation();
  const errorMessageKey = getErrorMessageKey(apiErrorDetails);
  return (
    <div
      className={sls(stc.root, {
        mobileView: isMobile,
      })}
    >
      <p
        className={st(classes.root, { isMobile })}
        data-error-message-key={errorMessageKey}
        data-hook="search-error-message"
      >
        {t(errorMessageKey, {
          error_code: apiErrorDetails?.code,
          error_title: apiErrorDetails?.message,
          requestID: apiErrorDetails?.requestId,
        })}
      </p>
    </div>
  );
};
