import { SearchDocumentType } from '@wix/client-search-sdk';
import type { ComponentType } from 'react';
import type { ListLayoutItemProps } from '../components/SearchResults/Widget/components/Layout.types';
import type { WidgetComponentProps } from '../components/SearchResults/Widget/components/Widget';
import type { LayoutItem } from '../components/SearchResults/Widget/components/LayoutItem.types';
import type { DesignTabSection } from '../components/SearchResults/Settings/tabs/DesignTab';

export enum LayoutType {
  Grid = 'grid',
  List = 'list',
}

export type IndexableApp = {
  documentType: SearchDocumentType;
  appDefId?: string;
  spec?: string;
  translations: {
    tabLabel: string;
    tabLabelWithCount: string;
    tabAccessibilityLabel: string;
  };
  layout: LayoutType;
};

export type ItemDecorationProps = {
  item: LayoutItem;
} & Partial<WidgetComponentProps>;

export interface SettingsProps {
  changeToDesignTab(section: DesignTabSection): void;
}

export type AppViewerOverrides = {
  documentType: SearchDocumentType;
  Item?: ComponentType<ListLayoutItemProps>;
  ItemDecoration?: ComponentType<ItemDecorationProps>;
  extraProps?: (keyof WidgetComponentProps)[];
};

export type AppEditorOverrides = {
  documentType: SearchDocumentType;
  Settings?: ComponentType<SettingsProps>;
};
