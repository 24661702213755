import { SearchDocumentType } from '@wix/client-search-sdk';
import { Experiments } from '@wix/yoshi-flow-editor';
import { getSupportedApps } from '../integrations/apps';

export const getTabLabelTranslations = (
  experiments: Experiments,
): Record<SearchDocumentType, string> =>
  getSupportedApps(experiments).reduce((labels, app) => {
    labels[app.documentType] = app.translations.tabLabel;
    return labels;
  }, {} as Record<SearchDocumentType, string>);

export const getTabLabelWithCountTranslations = (
  experiments: Experiments,
): Record<SearchDocumentType, string> =>
  getSupportedApps(experiments).reduce((labels, app) => {
    labels[app.documentType] = app.translations.tabLabelWithCount;
    return labels;
  }, {} as Record<SearchDocumentType, string>);

export const getTabAccessibilityLabelTranslations = (
  experiments: Experiments,
): Record<SearchDocumentType, string> =>
  getSupportedApps(experiments).reduce((labels, app) => {
    labels[app.documentType] = app.translations.tabAccessibilityLabel;
    return labels;
  }, {} as Record<SearchDocumentType, string>);
