import React from 'react';

import { useSearchEnvironment } from '../hooks';
import { SearchEnvironment } from '../contexts';

export interface InjectedSearchEnvironmentProps {
  searchEnvironment: SearchEnvironment;
}

export const withSearchEnvironment = <P extends InjectedSearchEnvironmentProps>(
  WrappedComponent: React.ComponentType<P>,
) => {
  const Wrapped: React.FC<Omit<P, keyof InjectedSearchEnvironmentProps>> = (
    props,
  ) => {
    const searchEnvironment = useSearchEnvironment();
    return <WrappedComponent {...({ ...props, searchEnvironment } as P)} />;
  };

  return Wrapped;
};
