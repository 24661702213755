import { ForumContentType as ForumContentTypeSDK } from '@wix/client-search-sdk';

export enum CommonForumFacetOptions {
  All = 'ALL',
}

export const ContentTypeOptions = {
  ...ForumContentTypeSDK,
  ...CommonForumFacetOptions,
};

export type ContentTypeOptionsType =
  | ForumContentTypeSDK
  | CommonForumFacetOptions;
