import * as React from 'react';

import { classes } from './ViewAllButton.st.css';
import { useTranslation } from '@wix/yoshi-flow-editor';

interface IViewAllButtonProps {
  onClick?(e: React.MouseEvent<HTMLElement>): void;
  url: string;
  'data-hook'?: string;
}

export const ViewAllButton: React.FC<IViewAllButtonProps> = (props) => {
  const { t } = useTranslation();
  const label = t('searchResults.samples.label.viewAll');

  return (
    <a
      title={label}
      href={props.url}
      data-hook={props['data-hook'] || 'view-all-button'}
      onClick={props.onClick}
      className={classes.root}
      role="button"
    >
      {label}
    </a>
  );
};
