import { WIX_CHALLENGES } from '@wix/app-definition-ids';
import { SearchDocumentType } from '@wix/client-search-sdk';
import { IndexableApp, LayoutType } from '../types';

export const ChallengesApp: IndexableApp = {
  documentType: SearchDocumentType.Programs,
  appDefId: WIX_CHALLENGES,
  translations: {
    tabLabel: 'searchResults.tabs.label.programs',
    tabLabelWithCount: 'searchResults.tabs.label.programs.withCount',
    tabAccessibilityLabel:
      'searchResults.tabs.label.search-category.accessibilityLabel',
  },
  layout: LayoutType.List,
};
