import React, { FC } from 'react';
import LikeHeartIcon from 'wix-ui-icons-common/on-stage/LikeHeart';
import CommentIcon from 'wix-ui-icons-common/on-stage/Comment';
import ReplyIcon from 'wix-ui-icons-common/on-stage/Reply';
import VisibleIcon from 'wix-ui-icons-common/on-stage/Visible';

import UpvoteIcon from './UpvoteIcon';
import DownvoteIcon from './DownvoteIcon';
import { classes, st } from './StatsBar.st.css';

interface StatElementProps {
  count: number;
  icon: React.FC<{ size?: string | number }>;
  suffixIcon?: React.FC<{ size?: string | number }>;
}

const StatElement: React.FC<StatElementProps> = ({
  count,
  icon: Icon,
  suffixIcon: SuffixIcon,
}) => (
  <div className={classes.statContainer}>
    <div className={classes.iconContainer}>
      <Icon size={20} />
    </div>
    <div className={classes.countContainer}>{count}</div>
    {SuffixIcon && (
      <div className={classes.suffixIconContainer}>
        <SuffixIcon size={20} />
      </div>
    )}
  </div>
);

interface StatsBarProps {
  score?: number;
  likes?: number;
  comments?: number;
  replies?: number;
  views?: number;
  containerClassName?: string;
}

const StatsBar: FC<StatsBarProps> = ({
  containerClassName,
  score,
  likes,
  comments,
  views,
  replies,
}) => {
  const hasScore = score !== undefined;
  const hasLikes = likes !== undefined;
  const hasComments = comments !== undefined;
  const hasViews = views !== undefined;
  const hasReplies = replies !== undefined;

  if (!hasScore && !hasLikes && !hasComments && !hasViews && !hasReplies) {
    return null;
  }

  return (
    <div className={st(classes.root, containerClassName)}>
      {hasScore && (
        <StatElement
          count={score}
          icon={UpvoteIcon}
          suffixIcon={DownvoteIcon}
        />
      )}
      {hasLikes && <StatElement icon={LikeHeartIcon} count={likes} />}
      {hasComments && <StatElement icon={CommentIcon} count={comments} />}
      {hasReplies && <StatElement icon={ReplyIcon} count={replies} />}
      {hasViews && <StatElement icon={VisibleIcon} count={views} />}
    </div>
  );
};

export default StatsBar;
