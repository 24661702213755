import React from 'react';

const UpvoteIcon = () => {
  // @TODO temporary inline svg, while icon is not available in wix-ui-tp
  return (
    <svg
      width="16"
      height="12"
      viewBox="0 0 16 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.594162 1.37561L0.594113 1.37554C0.465846 1.1895 0.475563 0.992217 0.581656 0.824474C0.691912 0.65015 0.917411 0.5 1.23236 0.5L14.7676 0.5C15.0826 0.5 15.3081 0.65015 15.4183 0.824474C15.5244 0.992217 15.5342 1.1895 15.4059 1.37554L15.4059 1.37557L8.6376 11.1942L8.63732 11.1946C8.50675 11.3844 8.26979 11.5 7.99985 11.5C7.72991 11.5 7.49233 11.3844 7.3611 11.1941L0.594162 1.37561Z"
        stroke="currentColor"
      />
    </svg>
  );
};

export default UpvoteIcon;
