import { CategoryOptions } from '../types/core/facets';

const MAX_COLLECTIONS_TO_DISPLAY = 10;

export const getVisibleCollections = (
  collections: CategoryOptions,
  selected: string[],
) => {
  const selectedUnknownCollections = selected
    .filter(
      (collectionName) => !collections.some((x) => x.label === collectionName),
    )
    .map((collectionName) => ({
      label: collectionName,
      count: 0,
    }))
    .slice(0, MAX_COLLECTIONS_TO_DISPLAY);

  const selectedCollections = collections
    .filter((collection) => selected.includes(collection.label))
    .map((collection) => collection.label)
    .slice(0, MAX_COLLECTIONS_TO_DISPLAY - selectedUnknownCollections.length);

  const notSelectedCollections = collections
    .filter((collection) => !selectedCollections.includes(collection.label))
    .map((collection) => collection.label)
    .slice(
      0,
      MAX_COLLECTIONS_TO_DISPLAY -
        selectedUnknownCollections.length -
        selectedCollections.length,
    );

  return [
    ...collections.filter(
      (collection) =>
        selectedCollections.includes(collection.label) ||
        notSelectedCollections.includes(collection.label),
    ),
    ...selectedUnknownCollections,
  ];
};
