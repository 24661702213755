import { Experiments } from '@wix/yoshi-flow-editor';

import { Spec } from '@wix/site-search-common';

interface ResultsMessageKeySettings {
  isWithNumber: boolean;
  isWithQuery: boolean;
}

const getResultsFoundMessageKey = (
  { isWithNumber, isWithQuery }: ResultsMessageKeySettings,
  {
    withNumberAndQuery,
    withNumber,
    withQuery,
  }: {
    withNumberAndQuery: string;
    withNumber: string;
    withQuery: string;
  },
) => {
  if (isWithNumber && isWithQuery) {
    return withNumberAndQuery;
  }

  return isWithNumber ? withNumber : withQuery;
};

const getResultsEmptyMessageKey = (
  { isWithNumber, isWithQuery }: ResultsMessageKeySettings,
  {
    empty,
    withNumberAndQuery,
    withNumber,
    withQuery,
  }: {
    empty: string;
    withNumberAndQuery: string;
    withNumber: string;
    withQuery: string;
  },
) => {
  if (!isWithNumber && !isWithQuery) {
    return empty;
  }

  if (isWithNumber && isWithQuery) {
    return withNumberAndQuery;
  }

  return isWithNumber ? withNumber : withQuery;
};

export const getNoFacetsResultsFoundMessageKey = (
  settings: ResultsMessageKeySettings,
  searchQueryLength: number,
): string => {
  return getResultsFoundMessageKey(settings, {
    withNumberAndQuery: searchQueryLength
      ? 'resultsFoundMessage.withNumberAndQuery'
      : 'resultsFoundMessage.empty-search.withNumberAndQuery',
    withNumber: 'resultsFoundMessage.withNumber',
    withQuery: searchQueryLength
      ? 'resultsFoundMessage.withQuery'
      : 'resultsFoundMessage.empty-search.withQuery',
  });
};

export const getFacetsResultsFoundMessageKey = (
  settings: ResultsMessageKeySettings,
  isMaxCount: boolean,
  searchQueryLength: number,
): string => {
  return getResultsFoundMessageKey(settings, {
    withNumberAndQuery: isMaxCount
      ? searchQueryLength
        ? 'resultsFoundMessage.withNumberAndQuery'
        : 'resultsFoundMessage.empty-search.withNumberAndQuery'
      : searchQueryLength
      ? 'facetsResultsFoundMessage.withNumberAndQuery'
      : 'facetsResultsFoundMessage.empty-search.withNumberAndQuery',
    withNumber: isMaxCount
      ? 'resultsFoundMessage.withNumber'
      : 'facetsResultsFoundMessage.withNumber',
    withQuery: searchQueryLength
      ? 'resultsFoundMessage.withQuery'
      : 'resultsFoundMessage.empty-search.withQuery',
  });
};

export const getNoFacetsResultsEmptyMessageKey = (
  searchQueryLength: number,
): string =>
  searchQueryLength
    ? 'resultsEmptyMessage.emptyTryNew'
    : 'resultsEmptyMessage.empty-search.emptyTryNew';

export const getFacetsResultsEmptyMessageKey = (
  settings: ResultsMessageKeySettings,
  searchQueryLength: number,
): string => {
  return getResultsEmptyMessageKey(settings, {
    empty: 'resultsEmptyMessage.empty',
    withNumberAndQuery: searchQueryLength
      ? 'facetsResultsEmptyMessage.withNumberAndQuery'
      : 'facetsResultsEmptyMessage.empty-search.withNumberAndQuery',
    withNumber: 'facetsResultsEmptyMessage.withNumber',
    withQuery: searchQueryLength
      ? 'noResultsFoundMessage.withQuery'
      : 'noResultsFoundMessage.empty-search.withQuery',
  });
};
