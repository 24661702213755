import React, { useRef } from 'react';
import { useEnvironment } from '@wix/yoshi-flow-editor';

import { ClearButton } from './ClearButton';
import { SearchIcon } from './SearchIcon';
import { ISearchBoxProps } from './SearchBox.types';

import { st, classes } from './SearchBox.st.css';

export const SearchBox: React.FC<ISearchBoxProps> = ({
  clearLabel,
  onChange,
  onClear,
  onSubmit,
  placeholder,
  value,
  totalCount,
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const { isMobile } = useEnvironment();
  const [isInputFocused, setInputFocused] = React.useState(false);

  const triggerFocus = React.useCallback(() => {
    if (totalCount === 0) {
      inputRef.current?.focus();
    } else {
      document.querySelector('main')?.focus();
    }
  }, [totalCount]);

  const handleSubmit: React.FormEventHandler = (event) => {
    event.preventDefault();
    onSubmit();
    inputRef.current?.blur();
    triggerFocus();
  };

  React.useEffect(() => {
    document.querySelector('main')?.focus();
  }, []);

  React.useEffect(() => {
    triggerFocus();
  }, [triggerFocus]);

  const handleFocus: React.FocusEventHandler = () => setInputFocused(true);
  const handleBlur: React.FocusEventHandler = () => setInputFocused(false);

  const handleContainerClick: React.MouseEventHandler = () => {
    inputRef.current?.focus();
  };

  return (
    <form
      action="#"
      className={st(classes.root, {
        inputFocused: isInputFocused,
        mobileView: isMobile,
      })}
      data-hook="search-box-form"
      role="search"
      onSubmit={handleSubmit}
      onClick={handleContainerClick}
    >
      <SearchIcon />
      <input
        type="search"
        className="has-custom-focus"
        ref={inputRef}
        aria-label={placeholder}
        placeholder={placeholder}
        value={value}
        autoComplete="off"
        onChange={onChange}
        onFocus={handleFocus}
        onBlur={handleBlur}
      />
      {!!value && <ClearButton label={clearLabel} onClick={onClear} />}
    </form>
  );
};

export default SearchBox;
