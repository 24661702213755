import React from 'react';

import { useHost } from '../hooks';
import { IHostProps } from '@wix/yoshi-flow-editor';

export interface InjectedHostProps {
  host: IHostProps;
}

export const withHost = <P extends InjectedHostProps>(
  WrappedComponent: React.ComponentType<P>,
) => {
  const Wrapped: React.FC<Omit<P, keyof InjectedHostProps>> = (props) => {
    const host = useHost();
    return <WrappedComponent {...({ ...props, host } as P)} />;
  };

  return Wrapped;
};
