import { ErrorMonitor } from '@wix/yoshi-flow-editor/error-monitor';
import parseJSON from 'date-fns/parseJSON';

import { reportError } from '../../../../../lib/errors';

export const getDateFormatter = (
  locale: string,
  errorMonitor: ErrorMonitor,
) => {
  return (date: string) => {
    const options: Intl.DateTimeFormatOptions = {
      day: 'numeric',
      month: 'long',
      year: 'numeric',
    };
    try {
      return new Intl.DateTimeFormat(locale, options).format(parseJSON(date));
    } catch (error) {
      reportError(errorMonitor, error);
      return '';
    }
  };
};

export const getTimeFormatter = (
  locale: string,
  errorMonitor: ErrorMonitor,
) => {
  return (date: string) => {
    const options: Intl.DateTimeFormatOptions = {
      hour: 'numeric',
      minute: 'numeric',
    };
    try {
      return new Intl.DateTimeFormat(locale, options).format(parseJSON(date));
    } catch (error) {
      reportError(errorMonitor, error);
      return '';
    }
  };
};

export const getCurrencyFormatter = (
  locale: string,
  errorMonitor: ErrorMonitor,
) => {
  return (price: number, currency: string): string => {
    try {
      return new Intl.NumberFormat(locale, {
        style: 'currency',
        currency,
      }).format(price);
    } catch (error) {
      reportError(errorMonitor, error);
      return '';
    }
  };
};
