import * as React from 'react';
import { useEnvironment } from '@wix/yoshi-flow-editor';

import { st, classes } from './Price.st.css';

interface IPriceProps {
  price: string;
}

export const Price: React.FC<IPriceProps> = ({ price }) => {
  const { isMobile } = useEnvironment();
  return (
    <span
      data-hook="item-price"
      className={st(classes.root, { mobileView: isMobile })}
    >
      {price}
    </span>
  );
};
