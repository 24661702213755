import * as React from 'react';

import { useEnvironment } from '@wix/yoshi-flow-editor';

import { NotificationIcon } from './NotificationIcon';

import { st, classes } from './DemoContentNotification.st.css';

export interface IDemoContentNotificationProps {
  text: string;
}

export const DemoContentNotification: React.FC<
  IDemoContentNotificationProps
> = ({ text }) => {
  const { isMobile } = useEnvironment();
  return (
    <div
      data-hook="demo-content-notification"
      className={st(classes.root, { mobileView: isMobile })}
    >
      <NotificationIcon className={classes.icon} />
      <span className={classes.text}>{text}</span>
    </div>
  );
};
