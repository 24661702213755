import React from 'react';
import { Spinner as TPASpinner } from 'wix-ui-tpa/cssVars';

import { st, classes } from './Spinner.st.css';

interface ISpinnerProps {
  dataHook?: string;
  monochrome?: boolean;
}

export const Spinner: React.FC<ISpinnerProps> = ({
  dataHook,
  monochrome = false,
}) => {
  return (
    <TPASpinner
      className={st(classes.root, { monochrome })}
      data-hook={dataHook}
      diameter={20}
      isCentered
    />
  );
};
