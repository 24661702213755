import { ISearchProductDocument } from '@wix/client-search-sdk';
import { useSettings } from '@wix/tpa-settings/react';
import React from 'react';
import { ItemDecorationProps } from '../../appsViewerOverrides';
import settingsParams from '../../../components/SearchResults/settingsParams';
import { useAppSettings } from '../../../components/SearchResults/Widget/hooks';
import { AddToCartButton } from '../AddToCartButton';
import { Price } from '../../../components/SearchResults/Widget/components/Price';

export function ProductItemDecorator({
  item,
  onProductAddToCart,
}: ItemDecorationProps) {
  const appSettings = useAppSettings();
  const settings = useSettings();

  const { price } = item;
  const showProductPrice = appSettings.isProductPriceVisible && !!price;
  const showProductAddToCart = settings.get(
    settingsParams.isProductsAddToCartEnabled,
  );

  return (
    <>
      {showProductPrice && <Price price={price} />}
      {showProductAddToCart && (
        <AddToCartButton
          onClick={() => {
            if (onProductAddToCart) {
              onProductAddToCart(item as ISearchProductDocument);
            }
          }}
          inStock={item.inStock ?? false}
        />
      )}
    </>
  );
}
