import { IWidgetProps } from '../../../components/SearchResults/Widget/components/Widget';

export const isProductCollectionsFacetVisible = ({
  facets,
  filter,
}: {
  facets: IWidgetProps['facets'];
  filter: IWidgetProps['facetFilters'];
}): boolean => {
  return (
    Boolean(facets.collections?.length) || Boolean(filter.collections?.length)
  );
};
