import { useErrorMonitor } from '@wix/yoshi-flow-editor';
import {
  getCurrencyFormatter,
  getDateFormatter,
  getTimeFormatter,
} from '../lib/formatters/get-formatters';
import { useSearchEnvironment } from './useSearchEnvironment';

export const useFormatters = () => {
  const { locale } = useSearchEnvironment();
  const errorMonitor = useErrorMonitor();

  return {
    formatCurrency: getCurrencyFormatter(locale, errorMonitor),
    formatDate: getDateFormatter(locale, errorMonitor),
    formatTime: getTimeFormatter(locale, errorMonitor),
  };
};
