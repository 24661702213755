import { SearchDocumentType } from '@wix/client-search-sdk';
import { GridLayout } from '../components/SearchResults/Widget/components/GridLayout';
import { LayoutItem } from '../components/SearchResults/Widget/components/LayoutItem.types';
import { ListLayout } from '../components/SearchResults/Widget/components/ListLayout';
import { WidgetComponentProps } from '../components/SearchResults/Widget/components/Widget';
import { AppViewerOverrides, LayoutType } from './types';
import { DesignTabSection } from '../components/SearchResults/Settings/tabs/DesignTab';
import { ForumAppUI } from './forum/app.viewer';
import { StoresAppUI } from './stores/app.viewer';
import { ProGalleryAppUI } from './proGallery/app.viewer';
import { EventsAppUI } from './events/app.viewer';

export type ItemDecorationProps = {
  item: LayoutItem;
} & Partial<WidgetComponentProps>;

export interface SettingsProps {
  changeToDesignTab(section: DesignTabSection): void;
}

export const LayoutComponentMap = {
  [LayoutType.Grid]: GridLayout,
  [LayoutType.List]: ListLayout,
};

const appsViewerOverrides: AppViewerOverrides[] = [
  ForumAppUI,
  StoresAppUI,
  ProGalleryAppUI,
  EventsAppUI,
];

export const findAppViewerOverrides = (
  documentType: SearchDocumentType,
): AppViewerOverrides | undefined =>
  appsViewerOverrides.find((app) => app.documentType === documentType);
