import * as React from 'react';

import { useEnvironment } from '@wix/yoshi-flow-editor';
import { ImageResizeMode } from '@wix/site-search-common';

import { Thumbnail, IThumbnailProps } from '../Thumbnail';
import { LayoutItemProps } from '../Layout.types';

import { classes } from './ListItemThumbnail.st.css';

export const LIST_LAYOUT_DEFAULT_THUMBNAIL_WIDTH = 176;
export const LIST_LAYOUT_DEFAULT_THUMBNAIL_HEIGHT = 124;

type ListItemThumbnailProps = {
  height?: number;
  image: IThumbnailProps['image'];
  onLinkClick: LayoutItemProps['onLinkClick'];
  title: string;
  url?: string;
  width?: number;
};

export const ListItemThumbnail: React.FC<ListItemThumbnailProps> = ({
  height = LIST_LAYOUT_DEFAULT_THUMBNAIL_HEIGHT,
  image,
  onLinkClick,
  title,
  url,
  width = LIST_LAYOUT_DEFAULT_THUMBNAIL_WIDTH,
}) => {
  const { isMobile, isEditorX, isCssPerBreakpoint } = useEnvironment();

  if (!isEditorX && !isCssPerBreakpoint && isMobile) {
    return null;
  }

  const thumbnail = (
    <Thumbnail
      height={height}
      image={image}
      maxResponsiveImageHeight={516}
      maxResponsiveImageWidth={516}
      onClick={(e) => onLinkClick?.(e, 'image')}
      resizeMode={ImageResizeMode.Crop}
      title={title}
      url={url}
      width={width}
    />
  );

  return isEditorX || isCssPerBreakpoint ? (
    <div className={classes.thumbnailContainer} style={{ minWidth: width }}>
      {thumbnail}
    </div>
  ) : (
    thumbnail
  );
};
